body {
  --background-color: #fafafa;
  --text-color: #52525b;

  --card-background-color: rgba(0, 0, 0, 0.015);
  --card-border-color: rgba(24, 24, 27, 0.08);
  --card-box-shadow-1: rgba(24, 24, 27, 0.02);
  --card-box-shadow-1-y: 3px;
  --card-box-shadow-1-blur: 6px;
  --card-box-shadow-2: rgba(24, 24, 27, 0.04);
  --card-box-shadow-2-y: 2px;
  --card-box-shadow-2-blur: 7px;
  --card-label-color: #18181b;
  --card-icon-color: #18181b;
  --card-icon-background-color: rgba(24, 24, 27, 0.04);
  --card-icon-border-color: rgba(24, 24, 27, 0.1);
  --card-shine-opacity: 0.3;
  --card-shine-gradient: conic-gradient(
    from 225deg at 50% 50%,
    rgba(59, 130, 246, 0) 0deg,
    #3b82f6 25deg,
    #9ec8ff 285deg,
    #ffffff 345deg,
    rgba(59, 130, 246, 0) 360deg
  );
  --card-line-color: #e9e9e7;
  --card-tile-color: rgba(59, 130, 246, 0.08);

  --card-hover-border-color: rgba(24, 24, 27, 0.15);
  --card-hover-box-shadow-1: rgba(24, 24, 27, 0.05);
  --card-hover-box-shadow-1-y: 3px;
  --card-hover-box-shadow-1-blur: 6px;
  --card-hover-box-shadow-2: rgba(24, 24, 27, 0.1);
  --card-hover-box-shadow-2-y: 8px;
  --card-hover-box-shadow-2-blur: 15px;
  --card-hover-icon-color: #1e3a8a;
  --card-hover-icon-background-color: rgba(59, 130, 246, 0.1);
  --card-hover-icon-border-color: rgba(59, 130, 246, 0.2);

  --blur-opacity: 0.1;

  &.light {
    --background-color: #fafafa;
    --text-color: #52525b;

    --card-background-color: transparent;
    --card-border-color: rgba(24, 24, 27, 0.08);
    --card-box-shadow-1: rgba(24, 24, 27, 0.02);
    --card-box-shadow-1-y: 3px;
    --card-box-shadow-1-blur: 6px;
    --card-box-shadow-2: rgba(24, 24, 27, 0.04);
    --card-box-shadow-2-y: 2px;
    --card-box-shadow-2-blur: 7px;
    --card-label-color: #18181b;
    --card-icon-color: #18181b;
    --card-icon-background-color: rgba(24, 24, 27, 0.04);
    --card-icon-border-color: rgba(24, 24, 27, 0.1);
    --card-shine-opacity: 0.3;
    --card-shine-gradient: conic-gradient(
      from 225deg at 50% 50%,
      rgba(59, 130, 246, 0) 0deg,
      #1b71fb 25deg,
      #dbeafe 285deg,
      #ffffff 345deg,
      rgba(59, 130, 246, 0) 360deg
    );
    --card-line-color: #e9e9e7;
    --card-tile-color: rgba(59, 130, 246, 0.08);

    --card-hover-border-color: rgba(24, 24, 27, 0.15);
    --card-hover-box-shadow-1: rgba(24, 24, 27, 0.05);
    --card-hover-box-shadow-1-y: 3px;
    --card-hover-box-shadow-1-blur: 6px;
    --card-hover-box-shadow-2: rgba(24, 24, 27, 0.1);
    --card-hover-box-shadow-2-y: 8px;
    --card-hover-box-shadow-2-blur: 15px;
    --card-hover-icon-color: #1e3a8a;
    --card-hover-icon-background-color: rgba(59, 130, 246, 0.1);
    --card-hover-icon-border-color: rgba(59, 130, 246, 0.34);

    --blur-opacity: 0.1;
  }

  &.toggle .cards-container * {
    transition-duration: 0s !important;
  }
}

.cards-container {
  // display: grid;
  // grid-template-columns: repeat(3, 340px);
  // grid-gap: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  position: relative;
  z-index: 1;
}

.card {
  width: 25%;
  background-color: var(--background-color);
  box-shadow:
    0px var(--card-box-shadow-1-y) var(--card-box-shadow-1-blur) var(--card-box-shadow-1),
    0px var(--card-box-shadow-2-y) var(--card-box-shadow-2-blur) var(--card-box-shadow-2),
    0 0 0 1px var(--card-border-color);
  padding: 56px 16px 16px 16px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.25s;
  font-family: "Inter", Arial;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    background-color: var(--card-background-color);
  }

  .icon {
    z-index: 2;
    position: relative;
    display: table;
    padding: 12px;
    bottom: 10%;

    &::after {
      content: "";
      position: absolute;
      inset: 4.5px;
      border-radius: 50%;
      border: 1px solid var(--card-icon-border-color);
    }

    img {
      position: relative;
      z-index: 1;
      display: block;
      width: 24px;
      height: 24px;
      transform: translateZ(0);
      transition: color 0.25s;
    }
  }

  h4 {
    z-index: 2;
    text-transform: uppercase;
    position: relative;
    margin: 12px 0 14px 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    color: var(--card-label-color);
  }

  p {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 20px;
  }
  ul {
    z-index: 2;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    font-size: 14px;
    line-height: 1.7;
    color: var(--text-color);

    li {
      margin-bottom: 4px;
    }
  }

  .shine {
    border-radius: inherit;
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s;

    &:before {
      content: "";
      width: 150%;
      padding-bottom: 150%;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      bottom: 55%;
      filter: blur(35px);
      opacity: var(--card-shine-opacity);
      transform: translateX(-50%);
      background-image: var(--card-shine-gradient);
    }
  }

  .background {
    border-radius: inherit;
    position: absolute;
    inset: 0;
    overflow: hidden;
    -webkit-mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);
    mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);

    .tiles {
      opacity: 0;
      transition: opacity 0.25s;

      .tile {
        position: absolute;
        background-color: var(--card-tile-color);
        animation-duration: 8s;
        animation-iteration-count: infinite;
        opacity: 0;

        &.tile-4,
        &.tile-6,
        &.tile-10 {
          animation-delay: -2s;
        }

        &.tile-3,
        &.tile-5,
        &.tile-8 {
          animation-delay: -4s;
        }

        &.tile-2,
        &.tile-9 {
          animation-delay: -6s;
        }

        &.tile-1 {
          top: 0;
          left: 0;
          height: 10%;
          width: 22.5%;
        }

        &.tile-2 {
          top: 0;
          left: 22.5%;
          height: 10%;
          width: 27.5%;
        }

        &.tile-3 {
          top: 0;
          left: 50%;
          height: 10%;
          width: 27.5%;
        }

        &.tile-4 {
          top: 0;
          left: 77.5%;
          height: 10%;
          width: 22.5%;
        }

        &.tile-5 {
          top: 10%;
          left: 0;
          height: 22.5%;
          width: 22.5%;
        }

        &.tile-6 {
          top: 10%;
          left: 22.5%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-7 {
          top: 10%;
          left: 50%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-8 {
          top: 10%;
          left: 77.5%;
          height: 22.5%;
          width: 22.5%;
        }

        &.tile-9 {
          top: 32.5%;
          left: 50%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-10 {
          top: 32.5%;
          left: 77.5%;
          height: 22.5%;
          width: 22.5%;
        }
      }
    }

    @keyframes tile {
      0%,
      12.5%,
      100% {
        opacity: 1;
      }

      25%,
      82.5% {
        opacity: 0;
      }
    }

    .line {
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity 0.35s;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: var(--card-line-color);
        transition: transform 0.35s;
      }

      &:before {
        left: 0;
        right: 0;
        height: 1px;
        transform-origin: 0 50%;
        transform: scaleX(0);
      }

      &:after {
        top: 0;
        bottom: 0;
        width: 1px;
        transform-origin: 50% 0;
        transform: scaleY(0);
      }

      &.line-1 {
        &:before {
          top: 10%;
        }

        &:after {
          left: 22.5%;
        }

        &:before,
        &:after {
          transition-delay: 0.3s;
        }
      }

      &.line-2 {
        &:before {
          top: 32.5%;
        }

        &:after {
          left: 50%;
        }

        &:before,
        &:after {
          transition-delay: 0.15s;
        }
      }

      &.line-3 {
        &:before {
          top: 55%;
        }

        &:after {
          right: 22.5%;
        }
      }
    }
  }

  &:hover {
    box-shadow:
      0px 3px 6px var(--card-hover-box-shadow-1),
      0px var(--card-hover-box-shadow-2-y) var(--card-hover-box-shadow-2-blur) var(--card-hover-box-shadow-2),
      0 0 0 1px var(--card-hover-border-color);

    .icon {
      &::after {
        background-color: var(--card-hover-icon-background-color);
        border-color: var(--card-hover-icon-border-color);
      }

      img {
        color: var(--card-hover-icon-color);
      }
    }

    .shine {
      opacity: 1;
      transition-duration: 0.5s;
      transition-delay: 0s;
    }

    .background {
      .tiles {
        opacity: 1;
        transition-delay: 0.25s;

        .tile {
          animation-name: tile;
        }
      }

      .line {
        opacity: 1;
        transition-duration: 0.15s;

        &:before {
          transform: scaleX(1);
        }

        &:after {
          transform: scaleY(1);
        }

        &.line-1 {
          &:before,
          &:after {
            transition-delay: 0s;
          }
        }

        &.line-2 {
          &:before,
          &:after {
            transition-delay: 0.15s;
          }
        }

        &.line-3 {
          &:before,
          &:after {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }
}

html {
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

// @keyframes levitate {
//   0%,
//   100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-20px);
//   }
// }

// .cards-container {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 2rem;
//   font-family: Arial, Helvetica, sans-serif;
//   gap: 2rem;
//   flex-wrap: wrap;
//   font-style: italic;
//   align-items: stretch;

//   .card-cont {
//     background-image: url();
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     width: 28%;
//     border-radius: 16px;
//     // border: 2px solid;
//     // border-color: rgba(77, 184, 244, 0.7917760854341737);
//     box-shadow: 0 4px 30px rgba(77, 184, 244, 0.3);
//   }
//   .card-cont:hover {
//     transform: translateY(-2px);
//     box-shadow:
//       0 14px 28px rgba(77, 184, 244, 0.3),
//       0 10px 10px rgba(77, 184, 244, 0.3);
//     background-size: contain;

//     .card {
//       backdrop-filter: blur(5px);
//     }
//   }

//   .card {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     // background-color: #60c0e2;
//     // padding: 2rem;
//     // border: 0.5px solid #ba2c37;
//     border-radius: 16px;
//     background: rgba(255, 255, 255, 0.2);
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(30px);
//     -webkit-backdrop-filter: blur(5px);
//     // width: 28%;
//     height: 100%;
//     overflow: hidden;

//     img {
//       width: 100%;
//       height: 50%;
//       border-radius: 16px;
//       align-self: center;
//     }
//     .card-text {
//       height: 50%;
//       // width: 100%;
//       margin: 1rem;

//       h2 {
//         color: rgb(64, 62, 62);
//         font-size: 24px;
//         text-align: center;
//         font-weight: bold;
//         padding: 10px 15px;
//         border-bottom: 2px solid #ba2c37;
//       }

//       .no-border {
//         border: none;
//         color: #ba2c37;
//         text-shadow: #ffff 1px 0 10px;
//         font-family: Arial, Helvetica, sans-serif;
//         font-style: normal;
//         font-size: 31px;
//         padding: 1.5rem;
//         margin: 2px;
//       }

//       ul {
//         padding: 0;
//         margin: 15px 30px;
//         list-style-type: none;

//         li {
//           color: rgb(64, 62, 62);
//           padding: 5px 5px;
//           font-size: 22px;
//           text-align: start;
//           font-style: normal;
//           justify-content: space-between;
//           font-family: Arial, Helvetica, sans-serif;
//         }
//       }
//     }
//     button {
//       display: flex;
//       justify-content: center;
//       align-self: center;
//       width: 50%;
//       padding: 10px 15px;
//       background-color: #ba2c37;
//       color: white;
//       border: none;
//       border-radius: 16px;
//       cursor: pointer;

//       &:hover {
//         background-color: darken(#ba2c37, 10%);
//       }
//     }
//   }

//   .new-card {
//     // height: 14rem;

//     .card-text {
//       align-content: start;

//       h2 {
//         text-align: start;
//         padding-bottom: 0;
//       }
//     }

//     ul {
//       display: flex;
//       flex-direction: column;
//       flex-wrap: wrap;
//     }
//   }
// }

// @media screen and (max-width: 1150px) {
//   .cards-container {
//     gap: 3rem;
//     padding: 0 1rem;

//     .card {
//       padding: 1.5rem;
//       width: 30%;
//       height: 65vh;
//       .card-text {
//         height: 40%;
//         h2 {
//           font-size: 16px;
//         }
//         ul li {
//           font-size: 13px;
//         }
//       }
//       button {
//         font-size: 12px;
//         width: 60%;
//       }
//     }
//   }
// }

// @media screen and (max-width: 950px) {
//   .cards-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 1rem;
//     padding: 0;
//     width: 85%;
//     height: 80%;

//     .card {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       padding: 1.5rem;
//       height: 25%;
//       width: 100%;

//       img {
//         height: 130px;
//         width: auto;
//       }
//       .card-text {
//         height: auto;
//         padding-left: 4rem;
//         h2 {
//           font-size: 16px;
//           text-align: start;
//           width: 200px;
//           padding: 5px 5px;
//         }
//         ul li {
//           font-size: 12px;
//           padding: 3px 3px;
//         }
//       }
//       button {
//         font-size: 12px;
//         width: 75px;
//         height: 75px;
//         margin-right: 1rem;
//         border-radius: 50%;
//         align-items: center;
//         animation: levitate 3s ease-in-out infinite;
//       }
//     }
//   }
// }

// @media screen and (max-width: 700px) {
//   .cards-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 1rem;
//     padding: 0;
//     width: 85%;
//     height: 80%;
//     align-content: center;

//     .card {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       padding: 1.5rem;
//       height: 25%;
//       width: 100%;

//       img {
//         height: 130px;
//         width: auto;
//       }
//       .card-text {
//         padding-left: 5%;
//         h2 {
//           font-size: 16px;
//           width: 100px;
//         }
//         ul li {
//           font-size: 12px;
//           padding: 3px 3px;
//           width: 190px;
//         }
//       }
//       button {
//         font-size: 12px;
//         width: 60px;
//         height: 60px;
//         border-radius: 50%;
//         align-items: center;
//       }
//     }
//     .new-card {
//       .card-text {
//         width: 100% !important;

//         h2 {
//           width: 90%;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 550px) {
  // .cards-container {
  //   align-content: center;
  //   .card {
  //     padding: 1rem;
  //     gap: 12px;
  //     justify-content: flex-start;

  //     img {
  //       height: 100px;
  //       width: 140px;
  //     }
  //     .card-text {
  //       width: 40%;

  //       h2 {
  //         font-size: 12px;
  //       }
  //       ul li {
  //         font-size: 10px;
  //         padding: 3px 1px;
  //       }
  //     }
  //     button {
  //       display: none;
  //     }
  //   }

  //   .new-card {
  //     .card-text {
  //       width: 100% !important;

  //       h2 {
  //         width: 90%;
  //       }
  //     }
  //   }
  // }

  .cards-container {
    // align-items: stretch;
    flex-direction: column;
    padding: 0 20px;

    .card {
      width: 100%;
    }
    // .card-cont {
    //   width: 65vw;
    //   .new-card {
    //     height: 10rem;
    //     width: 65vw;
    //   }
    // }
  }
}
