.call-btn {
  padding: 0;
  margin: 0;
  display: flex;

  a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }

  .contact-btn {
    background-color: #d22a38;
    padding: 15px 25px;
    border-radius: 25px;
  }
}

@media screen and (max-width: 850px) {
  .call-btn {
    a {
      font-size: 14px;
    }
    .contact-btn {
      padding: 10px 20px;
    }
  }
}

@media screen and (max-width: 550px) {
  .call-btn {
    margin-top: 10px;
    a {
      font-size: 12px;
    }
    .contact-btn {
      padding: 8px 15px;
    }
  }
}
