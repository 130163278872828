.page-title {
    h1 {
        color: #000;
        font-size: 4rem;
      }
}

.section-title {
    h2 {
        font-size: 54px;
        font-weight: 400;
        color: #000;
      }
}

.paragraph {
    p {
        color: #000;
        font-size: 18px;
        line-height: 1;
        font-family: "Raleway", Sans-serif;
      }
}