.gallery-container {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .gallery-title {
    color: rgb(64, 62, 62);
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}

@media screen and (max-width: 850px) {
  .gallery-container .gallery-title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .gallery-container .gallery-title {
    font-size: 2.5rem;
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
  justify-content: center;
  padding-top: 3rem;

  &__item {
    width: 350px;
    height: 300px;
    overflow: hidden;

    &:hover .gallery__image {
      transform: scale(1.1);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &__modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &-content {
      max-width: 700px;
      max-height: 80vh;
      display: flex;
      justify-content: center;
    }

    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 2rem;
      color: white;
      z-index: 20;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &-prev {
      left: 10%;
    }

    &-next {
      right: 10%;
    }

    &-image {
      max-width: 90%;
      max-height: 90vh;
    }
  }
}

@media screen and (max-width: 1150px) {
  .gallery__item {
    width: 250px;
    height: 200px;
  }
}

@media screen and (max-width: 850px) {
  .gallery__item {
    width: 200px;
    height: 150px;
  }
}

@media screen and (max-width: 550px) {
  .gallery__item {
    width: 150px;
    height: 100px;
  }
}
