// ***NAVBAR***

#nav-container .mobile-nav {
  display: none;
}
#nav-container {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  width: 100vw;
  background-color: #215b83;
  overflow-x: hidden;

  .Logo {
    height: 60px;
    width: auto;
  }
  .sidebar {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .menu-item {
      color: white;
      text-decoration: none;
      font-family: "Raleway", Sans-serif;
      font-size: 16px;
      letter-spacing: 3px;
      cursor: pointer;
      text-align: center;
      width: 50%;

      .arrow-icon {
        width: 15px;
        padding-left: 5px;
      }
    }

    .menu-item:hover {
      color: #72abfc;
    }

    .menu-item i {
      margin-right: 10px;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #faf9f8;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 400px;
      height: 280px;
    }

    /* Style the dropdown links */
    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      font-size: 14px;
      line-height: normal;
      text-align: start;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {
      padding-left: 15px;
      border-left: 4px solid #0b499f;
      transition: all 0.1s ease;
    }
  }
}

@media screen and (max-width: 850px) {
  #nav-container .sidebar .menu-item {
    font-size: 14px;
    letter-spacing: 1px;
    padding-right: 1rem;

    .dropdown:hover .dropdown-content {
      height: 250px;

      a {
        padding: 12px 10px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .sidebar {
    display: none;
  }

  #nav-container .mobile-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background-color: #3396dc;
    padding: 0 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;

    .Logo {
      height: 50px;
    }
    .burger__menu,
    .burger__closed {
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: transform 0.5s ease;
    }

    .burger__closed {
      transform: rotate(90deg);
    }

    #menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: none;
      background-color: white;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      transition: opacity 0.5s ease;
      line-height: 35px;
    }

    #menu.open {
      display: block;
    }

    li {
      padding: 10px 20px;
      transition: opacity 0.5s ease;

      a,
      .service-item {
        text-decoration: none;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: black;
        font-size: 14px;
        line-height: normal;

        &:hover {
          padding-left: 15px;
          border-left: 4px solid #cfaa69;
          transition: all 0.1s ease;
          color: #cfaa69;
        }
      }
    }
  }
}
