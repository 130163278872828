.service-page {
    height: 100vh;
    padding: 4rem;
    &_content {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
.page-title {
    margin-bottom: 2rem;
}
    }
    .service-list {
        display: flex;
        flex-direction: column;
        gap:  10px;
        .service-item {
            padding: 8px 15px;
            background-color: #d22a38;
            width: 12rem;
            border-radius: 12px;

            p {
                color: white;
                font-size: 16px;
                line-height: 1;
                font-family: "Raleway", Sans-serif;
              }
        }

    }
}