.about {
  width: 100vw;
}
.about-second__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;

  .about-title {
    font-size: 4rem;
    padding: 3rem 0;
    color: rgb(64, 62, 62);
  }

  .text-container__first {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    gap: 2rem;
    border-bottom: 2px solid #ba2c37;
    padding: 6rem;
    padding-top: 0;
    margin: 6rem;
    margin-top: 0;
    margin-bottom: 4rem;

    .bold {
      font-weight: bold;
    }
    p {
      font-size: 20px;
      line-height: 1.3;
      color: rgb(64, 62, 62);
    }
    .bolder {
      font-style: italic;
      font-weight: bold;
    }
  }

  .text-container__second {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    justify-content: space-between;
    gap: 1.5rem;
    // margin-top: 2rem;
    padding: 0 12rem;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: rgb(64, 62, 62);
    }
    p {
      font-size: 20px;
      line-height: 1.3;
      color: rgb(64, 62, 62);
    }
  }
}

@media screen and (max-width: 850px) {
  .about-second__container {
    .about-title {
      font-size: 3rem;
      padding: 2rem 0;
    }
    .text-container__first p {
      font-size: 18px;
    }
    .text-container__second p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 550px) {
  .about-second__container {
    .about-title {
      font-size: 2.5rem;
      padding: 2rem 0;
    }
    .text-container__first,
    .text-container__second {
      padding: 1rem 3rem;
      margin: 0;
      p {
        font-size: 14px;
      }
    }
  }
}
