@keyframes shrink-and-grow {
  0%,
  100% {
    transform: scale(1); // Original size
  }
  50% {
    transform: scale(0.1); // Shrink to 80% of the original size
  }
}

.about-third__container {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;

  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 2px solid #ba2c37;
    padding: 5rem 0 2rem 5rem;
    color: rgb(64, 62, 62);
  }

  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .image-container {
      img {
        height: 100%;
        border-radius: 16px;
        // width: 100%;
      }
    }

    .image-container {
      height: 30rem;
      width: 40%;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
        transition: opacity 1s ease-in-out;
      }

      img.fade-out {
        opacity: 0;
      }

      img.fade-in {
        opacity: 1;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 3rem 5rem;

    .row {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      align-items: center;

      p {
        font-size: 18px;
        color: rgb(64, 62, 62);
        font-weight: 600;
      }
      img {
        width: 40px;

        &:hover {
          animation: shrink-and-grow 0.5s ease-in-out;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .about-third__container .row p {
    line-height: normal;
  }
}

@media screen and (max-width: 550px) {
  .about-third__container {
    .subtitle {
      padding: 5rem 0 2rem 3rem;
      font-size: 1.2rem;
    }
    .links {
      padding: 3rem 3rem;
    }
    .row p {
      font-size: 14px;
    }
  }
  .container {
    flex-direction: column;
    .image-container {
      width: 80% !important;
    }
  }
}
