.service-container {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 5rem;

  .service-title {
    width: 100%;
    margin-left: 4rem;
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(64, 62, 62);
  }
}

@media screen and (max-width: 850px) {
  .service-container .service-title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .service-container .service-title {
    font-size: 2.5rem;
  }
  .service-container {
    margin: 0;
  }
}
