.banner-container {
  text-align: center;
  overflow: hidden;
  width: 100vw;
  margin-top: 2rem;

  p {
    font-size: 20px;
    font-weight: bold;
    margin: 3rem 0;
    color: rgb(64, 62, 62);
  }

  .banner {
    background-color: #009fdb;
    height: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .scroll-arrow {
      position: absolute;
      padding: 0 4rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 2;
      height: 30px;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }

    .banner-content {
      display: flex;
      align-items: center;
      overflow-x: auto;
      scroll-behavior: smooth;
      width: 80%;
      cursor: pointer;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      img {
        flex: 0 0 auto;
        max-height: 90px;
        object-fit: contain;
        margin-right: 100px;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .banner-container {
    .banner {
      .scroll-arrow {
        padding: 0 2rem;
      }

      img {
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .banner-container {
    .banner {
      .banner-content {
        width: 70%;
      }
      .scroll-arrow {
        padding: 0 1rem;
      }

      img {
        height: 50px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .banner-container {
    p {
      font-size: 14px;
    }
    .banner .banner-content {
      width: 70%;
      img {
        height: 40px;
        margin-right: 60px;
      }

      .scroll-arrow {
        padding: 0 1rem;
      }
    }
  }
}
