.home-section {
  background: linear-gradient(0deg, rgba(96, 95, 95, 0.7) 0%, rgba(0,0,0,0.6) 100%), url("../../assets/Images/backgroundHome.jpeg") no-repeat center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;

  display: flex;
  justify-content: space-around;
  align-items: center;

  .home-card {
    display: flex;
    justify-content: center;
    width: 45%;
    .home_card-container {
      // background-color: rgba(225, 225, 225, 0.601);
      width: 100%;
      padding: 2rem;
      border-radius: 16px;
      height: 340px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .card-header {
        h1 {
          font-size: 64px;
          font-weight: 600;
          color: #d22a38;
        }

        h2 {
          font-size: 54px;
          font-weight: 400;
          color: whitesmoke;
        }
      }

      p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        color: rgb(216, 215, 215)
      }
    }
  }

  .map-card {
    display: flex;
  }
}

@media screen and (max-width: 1150px) {
  .home-section .home-card .home_card-container {
    .card-header {
      h1 {
        font-size: 54px;
      }
      h2 {
        font-size: 50px;
      }
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 850px) {
  .home-section .home-card .home_card-container {
    height: 260px;
    .card-header {
      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 36px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .home-section .map-card iframe {
    max-height: 320px;
    width: 320px;
  }
}

@media screen and (max-width: 550px) {
  .home-section {
    display: flex;
    flex-direction: column;

    .home-card {
      width: 80%;
      height: 100px;
      margin: 2rem;
      .home_card-container {
        width: 85%;
        height: 160px;
        gap: 6px;
        .card-header {
          h1 {
            font-size: 36px;
          }
          h2 {
            font-size: 32px;
          }
        }
        p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .map-card iframe {
      width: 100%;
      max-height: 220px;
    }
  }
}
