.about-first__container {
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 4rem;
  margin-top: 4rem;
  font-family: Arial, Helvetica, sans-serif;

  .image-container {
    position: relative;
    width: 40%;
    height: 100%;
    background: url("../../../assets/Images/aboutImg.JPG") no-repeat center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    .img1 {
      position: absolute;
      width: 100%;
      z-index: 1;
      border-radius: 16px;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: normal;
    text-align: end;
    width: 40%;

    h1 {
      font-size: 2.3rem;
      border-bottom: 2px solid #ba2c37;
      padding-bottom: 0.3rem;
      margin-bottom: 2rem;
      color: rgb(64, 62, 62);
    }
    p {
      font-size: 20px;
      line-height: 1.3;
      color: rgb(64, 62, 62);
    }
  }
}

@media screen and (max-width: 1150px) {
  .about-first__container {
    padding: 0 2rem;
    .image-container img {
      height: 100%;
    }

    .text-container {
      width: 40%;
      h1 {
        font-size: 2.2rem;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .about-first__container {
    height: 80vh;
    padding: 0 2rem;

    .text-container {
      width: 40%;
      h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .about-first__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem 1rem;
    .image-container {
      width: 85%;
      height: 40%;
    }
    .text-container {
      width: 80%;
      justify-content: space-evenly;
      h1 {
        width: 100%;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
