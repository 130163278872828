.contact-section {
  display: flex;
  // justify-content: space-between;
  height: 80vh;
  padding-left: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;

  .wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .contact-content {
    width: 60%;
    font-family: Arial, Helvetica, sans-serif;

    h1 {
      color: rgb(64, 62, 62);
      font-size: 4rem;
      margin: 5rem 0 3rem 0;
    }

    p {
      color: rgb(64, 62, 62);
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .contact-tabs {
      display: flex;
      // justify-content: space-between;
      gap: 1rem;
      margin-top: 4rem;
      font-weight: bold;
      width: 100%;
      border-bottom: 2px solid #ba2c37;

      .contact-tab {
        cursor: pointer;
        h2 {
          color: rgb(64, 62, 62);
          font-weight: bold;
        }
      }
    }

    .tab-content {
      display: flex;
    }

    .tab-content .tab-panel {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;

      p {
        color: rgb(64, 62, 62);
        font-size: 16px;
        margin-bottom: 1rem;
        display: inline-grid;
        font-weight: bold;
      }
      a {
        font-size: 14px;
        text-decoration: none;
        color: #ba2c37;
        font-weight: bold;
        padding-top: 5px;
      }
    }
  }

  .contact-image {
    width: 50%;
    margin-right: 4rem;
    flex: 1;
    height: 71%;
    align-self: flex-end;
    border: 1px;
    // border-radius: 16px;

    background: url("../../assets/Images/contactImage.JPG") no-repeat left center;
    background-size: cover;
  }
}

@media screen and (max-width: 1150px) {
  .contact-section {
    padding: 0 2rem 0 4rem;
    .contact-image {
      height: 40%;
      width: 40%;
    }
    .contact-content {
      .contact-tabs {
        font-size: 14px;
        width: 50vw;
      }
      .tab-content .tab-panel {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .contact-section {
    padding: 0 1rem 0 4rem;
    .contact-image {
      display: none;
    }
    .contact-content {
      h1 {
        font-size: 3rem;
      }
      .contact-tabs {
        font-size: 13px;
      }
      .tab-content .tab-panel p,
      .tab-content .tab-panel a {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .contact-section {
    padding: 0;
    height: 60vh;

    .wrapper {
      flex: auto;
    }

    .contact-content {
      h1 {
        font-size: 3rem;
      }
      p {
        font-size: 14px;
        width: 60vw;
      }
      .contact-tabs {
        font-size: 14px;
        width: 80vw;
      }
      .tab-content .tab-panel p,
      .tab-content .tab-panel a {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .contact-section {
    // padding: 0 2rem 0 2rem;
    height: 80vh;

    .contact-content {
      h1 {
        font-size: 2.5rem;
      }
      p {
        font-size: 14px;
      }
      .contact-tabs {
        font-size: 12px;
        width: 80vw;
      }
    }
  }
}
