#banner .contact-banner {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40px;
  background-color: #60c0e2;
  bottom: 0;

  p {
    font-family: "Raleway", Sans-serif;
    font-size: 12px;
    align-self: center;
    color: white;
    font-weight: bold;
  }
}

@media screen and (max-width: 900px) {
  #banner .contact-banner {
    height: 30px;
    p {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 550px) {
  #banner .contact-banner {
    p {
      font-size: 8px;
      text-align: center;
    }
  }
}
